import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  HttpClientModule } from '@angular/common/http';
import { ErrorComponent } from './error/error.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MyapiService } from './services/myapi.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
   HttpClientModule,
   MatDialogModule,
   NgbModule 
  ],
  providers: [{provide: ErrorHandler,useExisting:MyapiService}],
  bootstrap: [AppComponent],
  entryComponents: [
    ErrorComponent
  ],

})
export class AppModule { }
