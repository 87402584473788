import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  { path: '', loadChildren: () => import('./components/payment/payment.module').then(m => m.PaymentModule) },
    { path: 'payment', loadChildren: () => import('./components/payment/payment.module').then(m => m.PaymentModule) },

    { path: 'direct', loadChildren: () => import('./components/direct/direct.module').then(m => m.DirectModule) }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',scrollPositionRestoration: 'enabled'
  })],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }